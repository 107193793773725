import React from "react";

import Layout from "../../../components/layout";
import { Helmet } from 'react-helmet';
import data from '../../../data/maintenance';

export default function () {

    return <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`${data.title} | Clean Air Filter`}</title>
        </Helmet>
        <article>
            <section>
                <h1><span className="tail"><span className="head">{data.content.section1.h1.head}</span> {data.content.section1.h1.tail}</span></h1>
                {/* <p dangerouslySetInnerHTML={{ __html: data.content.section1.p1 }}></p> */}
                <br></br>

                <article className="w-60">
                    <h2>{data.content.section2.p0}</h2>
                    <ul style={{ listStyle: 'none' }}>
                        <li><p>{data.content.section2.ul[0]}</p></li>
                        <li>
                            <p>{data.content.section2.ul[1]}</p>
                            <p>{data.content.section2.ul[2]}</p>
                            <ol>{data.content.section2.ul[3].map(item => <li><p dangerouslySetInnerHTML={{ __html: item }}></p></li>)}</ol>
                        </li>
                        {/* <li><p dangerouslySetInnerHTML={{ __html: data.content.section2.ul[3] }}></p></li> */}
                    </ul>
                    <p>{data.content.section2.p1}</p>
                </article>
                
                <br></br>
                <article className="w-60">
                    <h2>{data.content.section1.p2}</h2>
                    <ol style={{ listStyleType: 'upper-roman' }}>
                        <li><p>{data.content.section1.ol.li1}</p></li>
                        <li><p>{data.content.section1.ol.li2}</p></li>
                        <li>
                            <p>{data.content.section1.ol.li3[0]}</p>
                            <ol>
                                <li><p>{data.content.section1.ol.li3[1][0]}</p></li>
                                <li><p>{data.content.section1.ol.li3[1][1]}</p></li>
                                <li><p>{data.content.section1.ol.li3[1][2]}</p></li>
                            </ol>
                        </li>
                    </ol>
                </article>

                <br></br>
                <article>

                    <h2>{data.content.section3.p0}</h2>
                    <ul style={{ listStyle: 'none' }}>
                        <li><p dangerouslySetInnerHTML={{ __html: data.content.section3.ul[0] }}></p></li>
                    </ul>
                </article>
                <br></br>
                <article>

                    <h2>{data.content.section4.p0}</h2>

                    <ul style={{ listStyle: 'none' }}>
                        <li><p dangerouslySetInnerHTML={{ __html: data.content.section4.ul[0] }}></p></li>
                    </ul>
                    
                </article>
                
            </section>
        </article>
    </Layout>;
}
